import React from "react";
import { Button, Container } from "react-bootstrap";
import { AppStore } from "../stores/AppStore";
import { DiDropbox, DiOnedrive } from "react-icons/di";

import "./Dashboard.css";

export function Dashboard() {
  function onConnectDropbox() {
    AppStore.connectToDropbox().catch(console.error);
  }

  function onConnectOneDrive() {
    AppStore.connectToOneDrive().catch(console.error);
  }

  return (
    <Container fluid="sm">
      <h1>Your Computer</h1>
      <h2>Current settings</h2>
      <ul>
        <li>Storage Mode: {cloudModeToBrandName(AppStore.cloudMode)}</li>
      </ul>
      <div className="storage-select">
        <Button
          onClick={onConnectDropbox}
          variant={
            AppStore.cloudMode !== "dropbox" ? "outline-primary" : undefined
          }
        >
          <span className="icon">
            <DiDropbox />
          </span>
          Connect to Dropbox
        </Button>
        <Button
          onClick={onConnectOneDrive}
          variant={
            AppStore.cloudMode !== "onedrive" ? "outline-primary" : undefined
          }
        >
          <span className="icon">
            <DiOnedrive />
          </span>
          Connect to OneDrive
        </Button>
      </div>
    </Container>
  );
}

function cloudModeToBrandName(cloudMode: string | null) {
  switch (cloudMode) {
    case "dropbox":
      return "Dropbox";
    case "onedrive":
      return "OneDrive";
    case "nextcloud":
      return "NextCloud";
    case null:
      return "Not Set";
    default:
      return `Unknown: ${cloudMode}`;
  }
}
