import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import { AppStore } from "../stores/AppStore";
import { Container } from "react-bootstrap";

export const AutoLogin = observer(function AutoLogin() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(location.hash);
    if (location.hash.length <= 1) return;

    const deviceId = location.hash.substring(1);

    AppStore.login(deviceId)
      .then((ok) => {
        if (!ok) {
          navigate("/login");
          return;
        }

        navigate("/dashboard");
      })
      .catch(() => {
        navigate("/login");
      });
  }, [location.hash, navigate]);

  return (
    <Container fluid="sm">
      <p>Redirecting...</p>
    </Container>
  );
});
